<template>
  <div class="User_Profile_tab">
    <section>
      <h3>Default Pickup address</h3>
      <p>Save any Pickup address to quickly select on future shipments</p>
    </section>
    <section>
      <div class="encoding_form_wrapper">
        <div class="sub_sections">
          <h3>Contact Details</h3>
          <section>
            <div class="form_section1">
              <table>
                <tr>
                  <td>
                    <div class="label_and_element_wrapper">
                      <label>
                        Contact Name
                        <span>*</span>
                      </label>
                      <input type="text" placeholder="Name" v-model="profile.name" disabled/>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="label_and_element_wrapper">
                      <label>
                        Email
                        <span>*</span>
                      </label>
                      <input type="text" placeholder="Name" v-model="profile.email" disabled/>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="label_and_element_wrapper">
                      <label>
                        Company
                        
                      </label>
                      <input type="text" placeholder="Name" v-model="profile.company" disabled/>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="label_and_element_wrapper">
                      <label>
                        Branch Name
                        <span>*</span>
                      </label>
                      <input type="text" placeholder="Name" v-model="profile.branch" disabled/>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="label_and_element_wrapper">
                      <label>
                        Phone Number
                      
                      </label>
                      <div class="phone_input">
                        <input type="text" placeholder="+63" disabled/>
                        <input type="text" placeholder="Type Here" v-model="profile.phone" disabled/>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="label_and_element_wrapper">
                      <label>
                        Cost Center
                        <span>*</span>
                      </label>
                      <select v-model="profile.cost_center_id" disabled>
                        <option disabled >Select Cost center</option>
                        <option v-for="(data,i) in cost_centers" :value="data.id" :key="'cost_center'+i">{{data.name}}</option>
                      </select>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="profile-pic-container">
                    <div class="label_and_element_wrapper">
                      <label>
                        Profile Picture
                        <span>*</span>
                      </label>
                      <label class="user_profile_upload">
                        <span>Upload</span>
                        <input type="file" ref="profileImage" @change="profileImageUpload" />
                      </label>
                    </div>
                    <img :src="profile.avatar">
                  </td>
                </tr>
                
              </table>
            </div>
          </section>
        </div>
        <div class="sub_sections">
          <h3>Address Details</h3>
          <section>
            <div class="form_section1">
              <table>
                
                <tr>
                  <td>
                    <div class="label_and_element_wrapper">
                      <label>
                        Address Line 1
                        <span>*</span>
                      </label>
                      <input type="text" placeholder="Name" v-model="profile.address_1" disabled/>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="label_and_element_wrapper">
                      <label>
                        Address Line 2
                        <span>*</span>
                      </label>
                      <input type="text" placeholder="Name" v-model="profile.address_2" disabled/>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="label_and_element_wrapper">
                      <label>
                        City
                        <span>*</span>
                      </label>
                      <input type="text" placeholder="Name" v-model="profile.city" disabled/>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="label_and_element_wrapper">
                      <label>
                        Country
                     
                      </label>
                      <input type="text" placeholder="Name" v-model="profile.country" disabled/>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="label_and_element_wrapper">
                      <label>
                        State
                        <span>*</span>
                      </label>
                      <input type="text" placeholder="Name" v-model="profile.state" disabled/>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="label_and_element_wrapper">
                      <label>
                        Suburb 
                      
                      </label>
                      <input type="text" placeholder="Name" v-model="profile.suburb" disabled/>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="label_and_element_wrapper">
                      <label>
                        Postal Code
                        
                      </label>
                      <input type="text" placeholder="Name" v-model="profile.postal_code" disabled/>
                    </div>
                  </td>
                </tr>
              </table>
            </div>
          </section>
        </div>
      </div>
    </section>
    <section>
      <h3>RESET PASSWORD</h3>
      <div class="form_section1">
        <p>Save any password with atleast 12 characters( including special characters and numbers).</p>
        <table>
          <tr>
            <td>
              <div class="label_and_element_wrapper">
                <label>
                  Current password
                  <span>*</span>
                </label>
                <input type="password" placeholder="Type current password here" v-model="passwordChange.old_password"/>
              </div>
            </td>
            <td>
              <div class="label_and_element_wrapper">
                <label>
                  Password
                  <span class="dim_text">( Min 12 characters )</span>
                  <span>*</span>
                </label>
                <input type="password" placeholder="Type new password here" v-model="passwordChange.new_password"/>
              </div>
            </td>
          </tr>
          <tr>
            <td></td>
            <td>
              <input class="Update_Password" type="submit" value="Update Password"  @click = "updatePassword"  />
            </td>
          </tr>
        </table>
      </div>
    </section>
  </div>
</template>
<script>
  export default {
  name: "ProfileSettings",
  data() {
    return {
      profile:{
        name:"",
        email:"",
        company:"",
        branch:"",
        phone:"",
        cost_center_id:"",
        avatar:"",
        address_1:"",
        address_2:"",
        city:"",
        state:"",
        suburb:"",
        postal_code:"",
        country:""
      },
      cost_centers:[],
      images: "",
      passwordChange:{
        old_password:"",
        new_password:""
      }
    };
  },
  created(){
    this.axios.get("/api/user/data")
      .then(response => {
      console.log(response);
          this.profile.name = response.data.msg.name;
          this.profile.email = response.data.msg.email;
          this.profile.company = response.data.msg.company.name;
          this.profile.branch = response.data.msg.branch.name;
          this.profile.phone = response.data.msg.phone;
          this.profile.cost_center_id = response.data.msg.cost_center_id;
          this.profile.avatar = response.data.msg.avatar;
          this.profile.address_1 = response.data.msg.address_1;
          this.profile.address_2 = response.data.msg.address_2;
          this.profile.city = response.data.msg.city;
          this.profile.state = response.data.msg.state;
          this.profile.suburb = response.data.msg.suburb;
          this.profile.postal_code = response.data.msg.postal_code;
          this.profile.country = response.data.msg.country;
      })
      .catch(error =>{
          console.log(error);
      });
    this.fetchCost();
     
  },
  methods:{
    fetchCost(){
      this.axios.get("/api/cost")
      .then(response => {
        this.cost_centers = response.data.cost_centers;
      })
      .catch(error => {
          console.log(error);
      });
    },
    profileImageUpload() {
      this.images = this.$refs.profileImage.files[0];
      let formData = new FormData();
      formData.append("avatar", this.images);
console.log(formData);
      this.axios
        .post("/api/user/avatar", formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response => {
console.log(response);
          this.toast.success(response.data.msg);
          this.$router.go(this.$router.currentRoute);
        })
        .catch(error => {
          console.log(error);
        });
    },
    updatePassword() {
        if(!this.passwordChange.old_password || !this.passwordChange.new_password) {
          this.message = 'Please fill both fields';
          this.$toasted.show(this.message, { 
             theme: "primary", 
             position: "bottom-right", 
             type: 'error',
             duration: 2000,
             iconPack : 'material',
             icon : {
                    name : 'close'
                 }
          });
        }
        else {
          this.axios.put('/api/user/password', this.passwordChange)
          .then(response => {
            console.log(response);
            this.toast.success(response.data.msg);
            this.$router.go(this.$router.currentRoute);
          })
          .catch(error => {
            console.log(error.response);
          })
        }
      },
  }
};
</script>
<style scoped>
.User_Profile_tab h3 {
  font-size: 24px;
  font-weight: 700;
  padding: 20px 0 10px;
}
.dim_text {
  color: #999;
}
.Update_Password {
  float: right;
  display: block;
  border: 0;
  height: 40px;
  width: 200px;
  background: #004a7c;
  color: #fff;
  font-size: 18px;
  margin-right: 10px;
}
.user_profile_upload {
  display: flex;
  border: 0;
  height: 40px;
  width: 200px;
  background: #004a7c;
  color: #fff;
  font-size: 18px;
  margin-right: 10px;
  position: relative;
}
.user_profile_upload input {
  opacity: 0;
  width: 100%;
  height: auto;
  position: absolute;
  top: 0;
  left: 0;
}
.profile-pic-container{
  display:flex; 
  align-items:center;
}
.profile-pic-container img{
  width:60px;
  height:60px;
}
.user_profile_upload span {
  display: block;
  width: 100%;
  height: 40px;
  text-align: center;
  line-height: 30px;
  text-align: center;
  color: #fff;
  font-weight: normal;
}

.phone_input {
  position: relative;
  height: 40px;
  display: flex;
}
.phone_input input[type="text"]:first-child {
  background: #004a7c;
  color: #fff;
  width: 80px;
}
</style>
